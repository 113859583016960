<template>
  <div class="admin-container" v-if="data">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1 class="col-secondary-1">Projektování</h1>
      </v-col>
      <v-col
        align-self="center"
        v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      >
        <v-row class="justify-md-end">
          <!-- <v-col align-self="center" md="2" class="pa-0 flex-0 mt-8 mt-md-0"> -->
          <v-col align-self="center" md="2" class="pa-0 flex-md-0 mt-4 mt-md-0">
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              class="mobile-width-send-message"
              @click="$router.push({ name: 'Projecting-new' })"
              ><v-icon small color="primary">mdi-plus</v-icon> Nová
              složka</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h3 class="mt-5 mb-8 col-secodary-2">Aktuální verze projektu</h3>
    <h1
      v-if="!data.current_project_version.length"
      class="mt-10 mb-10 text-center"
    >
      Žádné verze projektu nenalezeny ..
    </h1>
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-for="it in data.current_project_version"
        :key="it.id"
      >
        <v-card
          class="pa-6"
          :to="{ name: 'Projecting-detail', params: { id: it.id } }"
        >
          <v-row>
            <v-col class="flex-grow-0">
              <div
                class="projecting-directory projecting-directory--no-border type-icon"
                :style="iconFolder"
              ></div>
            </v-col>
            <v-col>
              <div class="projecting-card-name">{{ it.name }}</div>
              <div class="projecting-card-date mt-2">{{ it.created_at }}</div>
              <div v-if="it.editor" class="projecting-card-editor mt-2">
                {{ it.editor.first_name }} {{ it.editor.last_name }}
              </div>
              <div class="d-flex align-center projecting-card-download">
                <v-btn text small @click.prevent="() => {}">
                  <img
                    :src="require('@/assets/stahnout.svg')"
                    class="icon-wrapp mr-2"
                    style="width: 15px; height: 15px"
                  />
                  <span style="font-size: 12px" @click="download(it.id)"
                    >Stáhnout</span
                  >
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-8" style="margin-top: 58px">
      <v-col>
        <h3 class="col-secodary-2">Stav</h3>
      </v-col>
      <v-col
        class="text-right"
        v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      >
        <v-btn tile small color="secondary" @click="store"
          >Uložit nový stav</v-btn
        >
      </v-col>
    </v-row>

    <v-row
      v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      class="mb-4"
    >
      <v-col cols="6" md="3">
        <!-- <v-text-field
          label="Datum"
          placeholder="D.M.R"
          v-model="currentProjectState.date"
        >
        </v-text-field> -->
        <DatePicker label="Datum" @dateChanged="changeDate($event)" />
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          item-text="name"
          item-value="val"
          :items="status"
          label="Status"
          v-model="currentProjectState.status"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field label="Předmět" v-model="currentProjectState.subject">
        </v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <!-- <v-text-field label="Poznámka" v-model="currentProjectState.note"> -->
        <v-textarea
          label="Poznámka"
          auto-grow
          v-model="currentProjectState.note"
          rows="1"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-divider
      class="primary"
      v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
    ></v-divider>

    <h3 class="col-secodary-2 mt-6">Aktuální stav řešení</h3>
    <v-row
      :class="`mt-6 ${stateIdx == 0 ? 'first-row-project-state' : ''}`"
      v-for="(state, stateIdx) in data.current_project_state"
      :key="state.id"
    >
      <v-col cols="6" md="2">
        <v-text-field
          label="Datum"
          placeholder="D.M.R"
          disabled
          v-model="state.date"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          item-text="name"
          item-value="val"
          :items="status"
          label="Status"
          v-model="state.status"
          disabled
        ></v-select>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field label="Předmět" disabled v-model="state.subject">
        </v-text-field>
      </v-col>
      <v-col
        cols="6"
        md="3"
        @click="showNote(state.note)"
        style="cursor: pointer"
      >
        <v-text-field
          @click="showNote(state.note)"
          label="Poznámka"
          disabled
          v-model="state.note"
        >
        </v-text-field>
      </v-col>
      <v-col
        align-self="center"
        v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
        ><v-btn tile color="secondary" x-small @click="handleFocusItem(state)"
          >Upravit</v-btn
        ></v-col
      >
    </v-row>
    <v-btn
      class="mt-4"
      tile
      color="secondary"
      small
      @click="showAll"
      v-if="!showMore"
      >Zobrazit vše</v-btn
    >
    <v-btn
      class="mt-4"
      tile
      color="secondary"
      small
      @click="showLess"
      v-if="showMore"
      >Zobrazit méně</v-btn
    >

    <v-row justify="center">
      <v-dialog
        v-model="noteDialog"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-text>
            <v-row class="pa-4">
              <v-col>
                {{ currentNote }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  small
                  text
                  @click="(noteDialog = false), (currentNote = null)"
                  width="100%"
                  >zavřít</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" v-if="focusedItem">
      <v-dialog
        v-model="editItemDialog"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-text>
            <v-row class="pa-4">
              <v-col> </v-col>
            </v-row>
            <DatePicker
              label="Datum"
              :initialDate="focusedItem.date"
              @dateChanged="changeFocusedItemDate($event)"
            />
            <!-- <v-text-field
              label="Datum"
              placeholder="D.M.R"
              v-model="focusedItem.date"
            >
            </v-text-field> -->
            <v-select
              item-text="name"
              item-value="val"
              :items="status"
              label="Status"
              v-model="focusedItem.status"
            ></v-select>
            <v-text-field label="Předmět" v-model="focusedItem.subject">
            </v-text-field>
            <v-textarea auto-grow label="Poznámka" v-model="focusedItem.note">
            </v-textarea>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  small
                  text
                  @click="(editItemDialog = false), (focusedItem = null)"
                  >zavřít</v-btn
                >
                <v-btn
                  small
                  color="primary"
                  text
                  @click="handleUpdateFocusedItem"
                  >Uložit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import authHandler from '../api/authHandler';
import permissionDeniedHandler from '../api/permissionDeniedHandler';
import bus from '../plugins/bus';
import projectingService from '../api/projectingService';
import downloadService from '../api/downloadService';
import errorHandler from '../api/errorHandler';
import DatePicker from '../components/DatePicker';
export default {
  data() {
    return {
      data: null,
      diplicatedState: null,
      showMore: false,
      noteDialog: false,
      currentNote: null,
      focusedItem: null,
      editItemDialog: false,
      currentProjectState: {
        status: null,
        section: 'CURRENT_PROJECT_STATE',
        subject: null,
        note: null,
        date: null,
      },
      status: [
        {
          val: 'ARCH_STUDIE',
          name: 'Arch. studie',
        },
        {
          val: 'PRIPRAVA_PD',
          name: 'Příprava PD.',
        },
        {
          val: 'PROJECTING',
          name: 'Projektování',
        },
        {
          val: 'STAVEBNI_URAD',
          name: 'Stavební úřad',
        },
        {
          val: 'VYDANI_SP',
          name: 'Vydání SP.',
        },
      ],
    };
  },
  components: {
    DatePicker,
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.index();
      } else {
        this.$router.push({ name: 'Dashboard' });
        bus.$emit('snackbar', {
          text: 'Není vybrána žádná stavba.',
          color: 'error',
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: 'Login' });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    async handleUpdateFocusedItem() {
      try {
        bus.$emit('processing');
        await projectingService.updateProjecting(
          this.focusedItem.id,
          this.focusedItem
        );
        this.editItemDialog = false;
        this.focusedItem = null;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    handleFocusItem(item) {
      console.debug(item);
      this.focusedItem = item;
      this.editItemDialog = true;
    },
    showNote(note) {
      this.currentNote = note;
      this.noteDialog = true;
    },
    changeDate(v) {
      this.currentProjectState.date = v;
    },
    changeFocusedItemDate(v) {
      console.debug(v);
      this.focusedItem.date = v;
    },
    async download(id) {
      try {
        const { data } = await downloadService.index('PARENT', id);
        await downloadService.downloadFromCode(data.code);
        console.debug(data);
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    showAll() {
      this.showMore = true;
      this.data.current_project_state = Object.assign([], this.diplicatedState);
    },
    showLess() {
      this.showMore = false;
      this.data.current_project_state.splice(
        this.data.current_project_state.length - 2,
        this.data.current_project_state.length - 2
      );
    },
    toMessages() {
      this.$router.push({
        name: 'MessagesDetail',
        params: { id: this.data.id },
      });
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    async store() {
      try {
        bus.$emit('processing');
        await projectingService.store({
          ...this.currentProjectState,
        });
        await this.index();
        // this.data.current_project_state.push({
        //   ...this.currentProjectState,
        //   id: Date.now(),
        // });
        this.currentProjectState = {
          status: null,
          section: 'CURRENT_PROJECT_STATE',
          subject: null,
          note: null,
          date: null,
        };
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },

    async index() {
      try {
        bus.$emit('processing');
        const data = await projectingService.index();
        this.data = data;
        this.diplicatedState = Object.assign([], data.current_project_state);
        if (this.data.current_project_state.length > 2) {
          this.data.current_project_state.splice(
            this.data.current_project_state.length - 2,
            this.data.current_project_state.length - 2
          );
        }
        console.debug(data);
        bus.$emit('processing', false);
        await bus.$emit('refresh_sections');
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require('@/assets/stahnout.svg')} !important`,
      };
    },
    iconFolder() {
      return { backgroundImage: `url(${require('@/assets/folder.svg')})` };
    },
  },
};
</script>

<style></style>
