<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
      v-if="loaded"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-if="!initialDate"
          v-model="dateFormatted"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          @blur="date = parseDate(dateFormatted)"
          @change="dateChanged"
          :disabled="
            disableUser && !['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
          "
        ></v-text-field>
        <!-- <pre>{{ $props }}</pre> -->
        <v-text-field
          v-if="initialDate"
          v-model="initialDate"
          :label="label"
          readonly
          :disabled="
            disableUser && !['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
          "
          v-bind="attrs"
          v-on="on"
          @blur="date = parseDate(initialDate)"
          @change="dateChanged"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="menu = false"> Zavřít </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date), dateChanged()"
        >
          Vybrat
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    initialDate: {
      type: String,
    },
    disableUser: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  data() {
    return {
      loaded: false,
      date: null,
      dateFormatted: null,
      // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // dateFormatted: this.formatDate(
      //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //     .toISOString()
      //     .substr(0, 10)
      // ),

      menu: false,
      modal: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    setTimeout(() => (this.loaded = true), 100);
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    dateChanged() {
      console.log("Date changed " + this.dateFormatted);
      this.$emit("dateChanged", this.dateFormatted);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
